import React from "react";
import Layout from "../../components/Layout";

const TRAVIS_COUNTY_INFO_URL =
  "https://www.traviscountytx.gov/news/2020/1945-novel-coronavirus-covid-19-information";

const TRAVIS_COUNTY_RISK_BASED_GUIDELINES_URL =
  "https://www.traviscountytx.gov/images/Vaccine_Risk_Based_Guidance_V14_EN.pdf";

class Covid extends React.Component {
  render() {
    return (
      <Layout title="Covid-19">
        <section className="section">
          <div className="container">
            <h3 className="subtitle is-2">Covid-19</h3>
            <h4 className="subtitle is-5">Updated: Oct 26, 2021</h4>
            <h4 className="subtitle is-3">Procedures for the Event</h4>

            <div className="notification is-success is-light">
              <span role="img" aria-label="party emoji">
                🎉
              </span>{" "}
              Austin has lowered restrictions to stage 3!
            </div>

            <p className="mb-4">
              While it is our heart’s desire to party with each and every one of
              you, we also want to keep everyone safe. Therefore, we plan to
              follow{" "}
              <a href={TRAVIS_COUNTY_INFO_URL} target="_blank" rel="noreferrer">
                Travis County Covid-19 guidelines
              </a>{" "}
              in effect November 6. For reference, Travis County is currently at{" "}
              <a
                href={TRAVIS_COUNTY_RISK_BASED_GUIDELINES_URL}
                target="_blank"
                rel="noreferrer"
              >
                stage 3
              </a>{" "}
              restrictions, which limit outdoor gatherings to vaccinated
              individuals, or unvaccinated individuals wearing a mask. We will
              continue to post updates here closer to the event.
            </p>

            <p className="mb-4">
              Please know that if you are not comfortable attending a wedding at
              this time, for any reason, we completely understand and will not
              be offended. We look forward to celebrating with you whenever we
              see you next!
            </p>
            <h4 className="subtitle is-3">
              Please take a Covid test — here’s how
            </h4>
            <p className="mb-4">
              We’re asking for all attendees to get a Covid test within 72 hours
              before the ceremony.
            </p>
            <p className="mb-4">
              If you are staying at the Courtyard/Residence Inn, you will find
              rapid tests in your welcome bag. If you need more tests, reach out
              to Dianne Frank and she can help you out.
            </p>
            <p className="mb-4">
              If you are not staying at the wedding hotel, here are some
              options:
            </p>
            <div className="content">
              <ul className="mb-4">
                <li>
                  You can pick up a test from the hotel (reach out to Dianne
                  Frank)
                </li>
                <li>
                  You can get tested at a pharmacy (CVS locations in Austin have
                  tests; an appointment is required)
                </li>
                <li>
                  We’ll also have rapid tests available at the wedding venue if
                  you don’t get the chance to get a test beforehand. Plan to
                  show up at least 30 minutes early to make sure you have time
                  to take the test!
                </li>
              </ul>
            </div>
            <p className="mb-4">
              If you test positive, unfortunately, we ask that you not attend
              the event in person. Please message Rick Frank so we know (contact
              details shared via email).
            </p>
          </div>
        </section>
      </Layout>
    );
  }
}

export default Covid;
